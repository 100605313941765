import React, { Component, ReactElement } from 'react';
import { whenv } from 'comm/util';

const About: React.FC = () => (
  <div className="ui shedkey secondary container segment">
    <h1 className="ui header screen only large pusher">About Shedkey</h1>
  </div>
);

const Terms: React.FC = () => (
  <div className="ui shedkey secondary container segment">
    <h1 className="ui header screen only large pusher">Terms of Use</h1>
    <ul>
      <li>Return an item in better shape than you received it.</li>
      <li>Do not pay or solicit payment for borrowing an item.</li>
      <li>Return items on time.</li>
    </ul>
  </div>
);

const Privacy: React.FC = () => (
  <div className="ui shedkey secondary container segment">
    <h1 className="ui header screen only large pusher">Privacy</h1>
  </div>
);

interface ContactState {
  name: string;
  email: string;
  message: string;
  sendAttempt: boolean;
}

interface FormError {
  field: string;
  error: string;
}

class Contact extends Component<{}, ContactState> {
  constructor() {
    super({});

    this.state = {
      name: '',
      email: '',
      message: '',
      sendAttempt: false,
    };
  }

  errors: () => FormError[] = () => {
    const { email, message } = this.state;
    const result: FormError[] = [];
    if (email === '') {
      result.push({ field: 'email', error: 'Please enter an email address' });
    }
    if (message === '') {
      result.push({ field: 'message', error: 'Please enter a message' });
    }
    return result;
  };

  send: () => void = () => {
    this.setState({ sendAttempt: true });

    if (this.errors().length === 0) {
      console.log('SENDING');
      console.log(this.state);
    }
  };

  render = (): ReactElement => {
    const es = this.errors();
    const { name, email, message, sendAttempt } = this.state;

    return (
      <div>
        <div className="ui shedkey secondary attached container segment">
          <h1 className="ui header screen only large pusher">Contact Us</h1>
          <p>
            We&apos;re trying to make this site better every day. Thank you so
            much for your feedback.
          </p>
          <div className="ui form">
            <div className="ui field">
              <label>Name</label>
              <input
                type="text"
                value={name}
                onChange={(e): void => this.setState({ name: e.target.value })}
              ></input>
            </div>
            <div className="ui field">
              <label>Email</label>
              <input
                type="text"
                value={email}
                onChange={(e): void => this.setState({ email: e.target.value })}
              ></input>
            </div>
            <div className="ui field">
              <label>Message</label>
              <textarea
                value={message}
                onChange={(e): void =>
                  this.setState({ message: e.target.value })
                }
              ></textarea>
            </div>
            <button
              type="button"
              className="ui primary left icon button"
              onClick={this.send}
            >
              <i className="ui send icon" />
              &nbsp;Send
            </button>
          </div>
        </div>
        {whenv(sendAttempt && es.length > 0)(
          <div className="ui bottom attached warning message container">
            {es.map((e) => (
              <div key={e.field} className="">
                {e.error}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };
}

export default About;

export { About, Terms, Privacy, Contact };
