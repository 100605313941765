import React, { Component, ReactNode } from 'react';
import { Map, TileLayer, Marker, Popup, Viewport } from 'react-leaflet';
import { LatLngTuple, ZoomAnimEvent, Icon, DivIcon } from 'leaflet';
import { Location } from 'comm/Common';
import { icon } from 'leaflet';
import shedSvg from 'assets/images/shed-icon.svg';
import { ifexv } from 'comm/util';

export const shedIcon = icon({
  iconUrl: shedSvg,
  iconSize: [32, 32],
  iconAnchor: [16, 0],
  popupAnchor: [0, 0],
});

class TileLayerBase extends React.Component<{}, {}> {
  constructor(props: {}) {
    super(props);

    this.state = {};
  }

  render = (): ReactNode => {
    const { children, ...rest } = this.props;

    return (
      <TileLayer
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        {...rest}
      >
        {children}
      </TileLayer>
    );
  };
}

export interface MapProps {
  updateLocation?: (center: Location) => void;
  editable: boolean;
  popup?: ReactNode;
  icon?: Icon | DivIcon;
  location: Location;
}

interface MapState {
  zoom: number;
}

export class ShedkeyMap extends Component<MapProps, MapState> {
  constructor(props: MapProps) {
    super(props);

    this.state = {
      zoom: 13,
    };
  }

  center: () => [number, number] = () => [
    this.props.location.lat,
    this.props.location.lng,
  ];

  drag: (e: Viewport) => void = (port) => {
    const newcenter: [number, number] = port.center || this.center();
    const { updateLocation } = this.props;

    if (updateLocation !== undefined) {
      updateLocation({
        lat: newcenter[0],
        lng: newcenter[1],
      });
    }
  };

  render = (): ReactNode => {
    const { zoom } = this.state;
    const {
      editable,
      icon,
      popup,
      location: { lat, lng },
    } = this.props;
    const position: LatLngTuple = [lat, lng];

    const cursor = editable ? 'grab' : 'default';

    const markerProps = {
      position,
      ...ifexv<{ icon?: Icon }>(icon !== undefined)({ icon: icon as Icon })({}),
    };

    return (
      <div style={{ height: '400px', width: '100%' }}>
        <Map
          center={position}
          zoom={zoom}
          onzoomlevelschange={(ev: ZoomAnimEvent): void =>
            this.setState({ zoom: ev.zoom })
          }
          style={{ height: '100%', width: '100%', cursor }}
          onViewportChange={this.drag}
          dragging={editable}
          touchZoom={editable}
          doubleClickZoom={editable}
          scrollWheelZoom={editable}
          boxZoom={editable}
          keyboard={editable}
          //          tap={editable} // TODO: need to make this work in mobile too, causes error.
        >
          <TileLayerBase />
          <Marker {...markerProps}>
            <Popup>{popup}</Popup>
          </Marker>
          {this.props.children}
        </Map>
      </div>
    );
  };
}

export default ShedkeyMap;
