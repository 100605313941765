import React, { HTMLAttributes, ReactElement } from 'react';

interface StringSplitProps {
  message: string;
}

function StringPs(
  props: StringSplitProps & HTMLAttributes<HTMLDivElement>
): ReactElement {
  const { message, ...rest } = props;
  const ps = message.split('\n\n').map((m, idx) => <p key={idx}>{m}</p>);

  return <div {...rest}>{ps}</div>;
}

export default StringPs;
