import { apiRoute, SiteResult, ApiRoute } from './Common';
import ProfileApi, { Profile } from './Profile';
import ItemApi, { Item, ItemId } from './Items';
import ShedApi, { ShedMap, ShedDetailMap } from './Shed';

export interface LoginData {
  username: string;
  password: string;
}

export interface AuthState {
  username: string | null;
  token: string | null;
}

export const emptyAuthState: AuthState = {
  username: null,
  token: null,
};

export interface AuthResult<T> {
  state: AuthState;
  data: T;
}

const ApiRoutes = {
  login: (data: LoginData): ApiRoute<LoginData, SiteResult<string>> =>
    apiRoute('/auth/login', 'POST', data),
  logout: (): ApiRoute<null, null> => apiRoute('/auth/logout', 'POST', null),
  signup: (data: LoginData): ApiRoute<LoginData, null> =>
    apiRoute('/auth/user', 'POST', data),
};

function makeAuthRequestR<D, R>(
  apiRoute: ApiRoute<D, R>
): (s: AuthState) => Promise<AuthResult<Response>> {
  const { uri, method } = apiRoute;

  return function (s: AuthState): Promise<AuthResult<Response>> {
    const body =
      apiRoute.method === 'POST' ? { body: JSON.stringify(apiRoute.data) } : {};

    const authorization: string[][] =
      s.token === null ? [] : [['Authorization', s.token as string]];

    const sr: Promise<AuthResult<Response>> = fetch(uri, {
      method,
      mode: 'cors',
      headers: [['Content-Type', 'application/json'], ...authorization],
      referrerPolicy: 'no-referrer',
      ...body,
    }).then((resp) => {
      if (!resp.ok) {
        return Promise.reject({
          state: { ...s, token: null },
          data: resp,
          status: resp.status,
        });
      }

      return Promise.resolve({
        state: { ...s, token: resp.headers.get('authorization') || s.token },
        data: resp,
      });
    });

    return sr;
  };
}

function makeAuthRequest<D, R>(
  apiRoute: ApiRoute<D, R>
): (s: AuthState) => Promise<AuthResult<R>> {
  return function (s: AuthState): Promise<AuthResult<R>> {
    return makeAuthRequestR(apiRoute)(s).then((rs) => {
      const unwrapped: Promise<R> = rs.data.json();

      return unwrapped.then((u) => ({
        ...rs,
        data: u,
      }));
    });
  };
}

function makeAuthRequest_<D>(
  apiRoute: ApiRoute<D, null>
): (s: AuthState) => Promise<AuthResult<null>> {
  return function (s: AuthState): Promise<AuthResult<null>> {
    return makeAuthRequestR(apiRoute)(s).then((rs) => ({
      ...rs,
      data: null,
    }));
  };
}

function initState(
  s: AuthState
): Promise<[Profile, (Item & ItemId)[], ShedMap, ShedDetailMap]> {
  //Promise<[Profile, [string, Item][], [string, Shed][]]>
  return Promise.all([
    ProfileApi.profile(s).then((r) => r.data),
    ItemApi.items(s).then((r) => r.data),
    ShedApi.userSheds(s).then((r) => r.data),
  ]).then(([profile, items, sheds]) =>
    Promise.all(
      Object.keys(sheds).map((shedId) =>
        ShedApi.shedDetail(shedId, s).then((shedDetail) => ({
          [shedId]: shedDetail.data,
        }))
      )
    ).then((shedDetails) => [
      profile,
      items,
      sheds,
      shedDetails.reduce((a, b) => ({ ...a, ...b }), {}),
    ])
  );
}

function login(data: LoginData, s: AuthState): Promise<AuthResult<string>> {
  return makeAuthRequest(ApiRoutes.login(data))(s).then((r) => ({
    ...r,
    data: r.data.result,
  }));
}

function signup(data: LoginData, s: AuthState): Promise<AuthResult<null>> {
  return makeAuthRequest_(ApiRoutes.signup(data))(s);
}

const logout: (s: AuthState) => Promise<null> = (s) =>
  makeAuthRequest_(ApiRoutes.logout())(s).then(() => null);

export default {
  login,
  signup,
  logout,
  initState,
};

export { makeAuthRequest, makeAuthRequest_, makeAuthRequestR };
