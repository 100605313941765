import { apiRoute, EditRequest, Location, ApiRoute } from './Common';

import {
  AuthState,
  AuthResult,
  makeAuthRequest,
  makeAuthRequest_,
} from './Auth';

export interface Profile {
  userId?: string;
  publicName?: string;
  publicLocation?: Location;
  privateLocation?: Location;
  publicAddress?: string;
  privateAddress?: string;
  verifiedEmail?: boolean;
}

export const emptyProfile: Profile = {
  userId: '',
  publicName: undefined,
  publicLocation: undefined,
  privateLocation: undefined,
  publicAddress: undefined,
  privateAddress: undefined,
  verifiedEmail: undefined,
};

const ApiRoutes = {
  profile: (): ApiRoute<null, Profile> => apiRoute('/profile', 'GET', null),
  initProfile: (): ApiRoute<null, null> =>
    apiRoute('/profile/init', 'POST', null),
  editProfile: (data: Profile): ApiRoute<EditRequest<Profile>, string> =>
    apiRoute('/profile', 'POST', { edits: data }),
  verifyEmail: (userId: string, verifyId: string): ApiRoute<null, null> =>
    apiRoute(`/profile/verify/${userId}/${verifyId}`, 'GET', null),
};

function editProfile(data: Profile, s: AuthState): Promise<AuthResult<string>> {
  return makeAuthRequest(ApiRoutes.editProfile(data))(s);
}

function profile(s: AuthState): Promise<AuthResult<Profile>> {
  return makeAuthRequest(ApiRoutes.profile())(s);
}

function initProfile(s: AuthState): Promise<AuthResult<null>> {
  return makeAuthRequest_(ApiRoutes.initProfile())(s);
}

function verifyEmail(
  userId: string,
  verifyId: string,
  s: AuthState
): Promise<AuthResult<null>> {
  return makeAuthRequest_(ApiRoutes.verifyEmail(userId, verifyId))(s);
}

export default {
  editProfile,
  profile,
  initProfile,
  verifyEmail,
};
