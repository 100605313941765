import React from 'react';
import invertedLogo from 'assets/images/logo-inverted.svg';
import { Link } from 'react-router-dom';

export const Footer: React.FC = () => (
  <div className="fluid pusher container shedkey background footer segment">
    <div className="ui center aligned container">
      <img alt="shedkey logo" src={invertedLogo} />
      <div className="ui inverted center aligned text">
        <div className="ui horizontal inverted small divided link list">
          <Link to="/terms" className="item">
            Terms
          </Link>
          <Link to="/contact" className="item">
            Contact Us
          </Link>
          <Link to="/about" className="item">
            About
          </Link>
          <Link to="/privacy" className="item">
            Privacy
          </Link>
        </div>
        <p>Copyright 2020, Shedkey</p>
        <p>Shedkey.com is a product of ClovellyTech LLC</p>
      </div>
    </div>
  </div>
);

export default Footer;
