import { ChangeEvent } from 'react';

export interface SiteResult<T> {
  result: T;
}

export interface ManyRequest<T> {
  many: T[];
}

export interface ApiRoute<D, R> {
  uri: string;
  method: 'POST' | 'GET';
  data: D;
  result: R | null;
}

export interface Location {
  lat: number;
  lng: number;
}

export interface MutFieldProps<E extends HTMLElement> {
  value: string;
  onChange: (ev: ChangeEvent<E>) => void;
}

export const defaultStartingLocation: Location = {
  lat: 41.880984,
  lng: -87.633297,
};

export interface EditRequest<A> {
  edits: A;
}

export function apiRoute<D, R>(
  uri: string,
  method: 'POST' | 'GET',
  data: D
): ApiRoute<D, R> {
  const apiRoot =
    process.env.NODE_ENV === 'production' ? '/api' : 'http://localhost:8080';

  return {
    uri: apiRoot + uri,
    method,
    data,
    result: null,
  };
}

export const Status = {
  Ok: 400,
  Conflict: 409,
  NotFound: 404,
  Unauthorized: 401,
};
