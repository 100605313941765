import React, { Component, ReactElement, HTMLAttributes } from 'react';
import { ifexv } from 'comm/util';

interface TabContainerProps {
  buttons: ReactElement[];
  initActiveIndex?: number;
  activeIndex?: number;
}

interface TabContainerState {
  activeIndex: number;
}

class TabContainer extends Component<
  TabContainerProps & HTMLAttributes<HTMLDivElement>,
  TabContainerState
> {
  constructor(props: TabContainerProps) {
    super(props);

    this.state = ifexv<TabContainerState>(props.initActiveIndex !== undefined)({
      activeIndex: props.initActiveIndex as number,
    })({
      activeIndex: 0,
    });
  }

  activeIndex = (): number =>
    ifexv<number>(this.props.activeIndex !== undefined)(
      this.props.activeIndex as number
    )(this.state.activeIndex);

  select = (i: number): void => this.setState({ activeIndex: i });

  render = (): ReactElement => {
    const { className, buttons, children } = this.props;
    const cs = children as ReactElement[];
    return (
      <div className={className}>
        <div className="ui buttons">
          {buttons.map(
            (b: ReactElement, i: number): ReactElement => {
              const classPart = ifexv<string>(this.activeIndex() === i)(
                'active'
              )('');
              return (
                <button
                  type="button"
                  onClick={(): void => this.select(i)}
                  className={`ui ${classPart} button`}
                  key={i}
                >
                  {b}
                </button>
              );
            }
          )}
        </div>
        {cs[this.activeIndex()]}
      </div>
    );
  };
}

export default TabContainer;

export { TabContainer };
