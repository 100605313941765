import React, { Component, ChangeEvent, ReactNode, ReactElement } from 'react';
import { MutFieldProps } from 'comm/Common';
import { Redirect } from 'react-router-dom';
import { ifex, when } from 'comm/util';
import { ShedProvisional } from 'comm/Shed';
import { ShowShed } from './ShedPage';
import TabContainer from 'components/TabContainer';
import { Login, Signup } from 'components/Auth';
import { LoginData } from 'comm/Auth';

interface JoinInviteProps {
  onComplete: (code: string) => void;
}

interface JoinInviteState {
  inviteText: string;
  submitted: boolean;
}

class JoinInviteForm extends Component<JoinInviteProps, JoinInviteState> {
  constructor(props: JoinInviteProps) {
    super(props);

    this.state = {
      inviteText: '',
      submitted: false,
    };
  }

  evUpdate: (field: string) => (ev: ChangeEvent<HTMLInputElement>) => void = (
    field
  ) => (ev): void => {
    let update = {};
    if (ev === null) {
      return;
    }
    const value: string = ev.target.value;

    if (field === 'inviteText') {
      update = { inviteText: value };
    }

    this.setState(update);
  };

  fieldProps: (field: string) => MutFieldProps<HTMLInputElement> = (field) => {
    let value = '';
    if (field === 'inviteText') {
      value = this.state.inviteText;
    }
    return {
      value,
      onChange: this.evUpdate(field),
    };
  };

  onComplete = (): void => {
    this.setState({ submitted: true });
    this.props.onComplete(this.state.inviteText);
  };

  render = (): ReactNode => {
    const { submitted } = this.state;

    return (
      <div className="ui form">
        <div className="field">
          <label>Enter invite code you were provided!</label>
          <input
            disabled={submitted}
            type="text"
            {...this.fieldProps('inviteText')}
          />
        </div>
        <div className="field">
          {when<ReactElement>(!submitted)(() => (
            <button
              disabled={this.state.inviteText === ''}
              className="ui primary button"
              type="button"
              onClick={this.onComplete}
            >
              Join
            </button>
          ))}
        </div>
      </div>
    );
  };
}

export interface JoinInvitePageProps {
  onComplete: (code: string) => void;
  completeRedirect?: string;
}

interface JoinInvitePageState {
  completed: boolean;
}

class JoinInvitePage extends Component<
  JoinInvitePageProps,
  JoinInvitePageState
> {
  constructor(props: JoinInvitePageProps) {
    super(props);

    this.state = {
      completed: false,
    };
  }

  onComplete = (code: string): void => {
    this.setState({ completed: true });
    this.props.onComplete(code);
  };

  render = (): ReactNode => {
    const { completed } = this.state;
    const { completeRedirect, children } = this.props;

    return ifex<ReactNode>(completed && completeRedirect !== undefined)(() => (
      <Redirect to={completeRedirect as string} />
    ))(() => (
      <div className="ui shedkey secondary container segment">
        <JoinInviteForm onComplete={this.onComplete} />
        {children}
      </div>
    ));
  };
}

type LoginFun = (d: LoginData) => Promise<void>;

interface JoinInviteUnauthProps {
  shedProvisional?: ShedProvisional;
  loginJoin?: LoginFun;
  signupJoin?: LoginFun;
  doneRedirect?: ReactElement;
}

class JoinInvitePageUnauth extends Component<
  JoinInvitePageProps & JoinInviteUnauthProps,
  {}
> {
  render = (): ReactNode => {
    const {
      shedProvisional,
      loginJoin,
      signupJoin,
      doneRedirect,
      ...props
    } = this.props;

    return (
      <JoinInvitePage {...props}>
        {when<ReactNode>(shedProvisional !== undefined)(() => {
          const sp = shedProvisional as ShedProvisional;
          return ifex<ReactElement>(doneRedirect !== undefined)(() => (
            <div>
              <p>Success!</p>
              {doneRedirect}
            </div>
          ))(() => (
            <div>
              <p>Log in or Sign up to join this shed</p>
              <TabContainer
                buttons={[
                  <span key="login">Log in</span>,
                  <span key="signup">Sign up</span>,
                ]}
              >
                <Login formSwap={false} onSubmit={loginJoin as LoginFun} />
                <Signup formSwap={false} onSubmit={signupJoin as LoginFun} />
              </TabContainer>
              <ShowShed
                shed={{ owner: '', ...sp.shed }}
                shedId={sp.shedId}
                userDetails={sp.users}
              />
            </div>
          ));
        })}
      </JoinInvitePage>
    );
  };
}

export default JoinInvitePage;

export { JoinInvitePageUnauth };
