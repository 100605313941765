import { Location, apiRoute, SiteResult, ApiRoute } from './Common';
import { Item, ItemId } from './Items';

import {
  AuthState,
  AuthResult,
  makeAuthRequest,
  makeAuthRequest_,
} from './Auth';

export interface ShedId {
  shedId: string;
}

export interface Shed {
  name: string;
  description: string;
  location: Location;
}

export interface OwnerId {
  owner: string;
}

export interface ShedMap {
  [shedId: string]: Shed & OwnerId;
}

export interface ShedDetailMap {
  [shedId: string]: ShedUserDetail[];
}

interface ShedCommMap {
  [shedId: string]: { shed: Shed; owner: string };
}

interface JoinRequest {
  code: string[];
}

interface InviteId {
  inviteId: string;
}

export interface PublicUser {
  userId: string;
  publicName: string;
  publicLocation: Location;
  publicAddress: string;
  membershipType: string;
}

export interface ShedUserDetail {
  user: PublicUser;
  items: (Item & ItemId)[];
}

export interface InvitationSummary {
  code: string[];
}

export interface ShedOwnerDetail {
  invitations: InvitationSummary[];
}

export interface ShedOwnerMap {
  [shedId: string]: ShedOwnerDetail;
}

export interface ShedProvisional {
  shed: Shed;
  shedId: string;
  users: ShedUserDetail[];
  inviteCode: string;
}

const ApiRoutes = {
  usersSheds: (): ApiRoute<null, SiteResult<ShedCommMap>> =>
    apiRoute('/shed', 'GET', null),
  create: (shed: Shed): ApiRoute<Shed, null> => apiRoute('/shed', 'POST', shed),
  joinFromInvite: (code: string[]): ApiRoute<JoinRequest, null> =>
    apiRoute('/invite/join', 'POST', { code }),
  joinFromInviteId: (inviteId: string): ApiRoute<InviteId, null> =>
    apiRoute('/invite/join-id', 'POST', { inviteId }),
  joinFromInviteUnAuth: (
    code: string[]
  ): ApiRoute<JoinRequest, SiteResult<ShedProvisional>> =>
    apiRoute('/invite/join_', 'POST', { code }),
  shedDetail: (shedId: string): ApiRoute<null, SiteResult<ShedUserDetail[]>> =>
    apiRoute(`/shed/${shedId}`, 'GET', null),
  shedOwnerDetail: (
    shedId: string
  ): ApiRoute<null, SiteResult<ShedOwnerDetail>> =>
    apiRoute(`/shed/owner-detail/${shedId}`, 'GET', null),
  createInvite: (shedId: string): ApiRoute<ShedId, null> =>
    apiRoute(`/invite/create-global`, 'POST', { shedId }),
};

const collapseShedData = (commMap: ShedCommMap): ShedMap => {
  const res: ShedMap = {};
  Object.keys(commMap).forEach((k) => {
    res[k] = {
      ...commMap[k].shed,
      owner: commMap[k].owner,
    };
  });
  return res;
};

const userSheds = (s: AuthState): Promise<AuthResult<ShedMap>> =>
  makeAuthRequest(ApiRoutes.usersSheds())(s).then((res) => ({
    ...res,
    data: collapseShedData(res.data.result),
  }));

const create = (shed: Shed, s: AuthState): Promise<AuthResult<null>> =>
  makeAuthRequest_(ApiRoutes.create(shed))(s);

function joinFromInvite(code: string, s: AuthState): Promise<AuthResult<null>> {
  return makeAuthRequest_(ApiRoutes.joinFromInvite(code.split(' ')))(s);
}

function joinFromInviteId(
  inviteId: string,
  s: AuthState
): Promise<AuthResult<null>> {
  return makeAuthRequest_(ApiRoutes.joinFromInviteId(inviteId))(s);
}

function joinFromInviteUnauth(
  code: string,
  s: AuthState
): Promise<AuthResult<ShedProvisional>> {
  return makeAuthRequest(ApiRoutes.joinFromInviteUnAuth(code.split(' ')))(
    s
  ).then((res) => ({
    ...res,
    data: res.data.result,
  }));
}

const shedOwnerDetail = (
  shedId: string,
  s: AuthState
): Promise<AuthResult<ShedOwnerDetail>> =>
  makeAuthRequest(ApiRoutes.shedOwnerDetail(shedId))(s).then((res) => ({
    ...res,
    data: res.data.result,
  }));

function shedDetail(
  shedId: string,
  s: AuthState
): Promise<AuthResult<ShedUserDetail[]>> {
  return makeAuthRequest(ApiRoutes.shedDetail(shedId))(s).then((res) => ({
    ...res,
    data: res.data.result,
  }));
}

const createInvite = (
  shedId: string,
  s: AuthState
): Promise<AuthResult<null>> =>
  makeAuthRequest_(ApiRoutes.createInvite(shedId))(s);

export default {
  userSheds,
  create,
  joinFromInvite,
  joinFromInviteId,
  joinFromInviteUnauth,
  shedDetail,
  shedOwnerDetail,
  createInvite,
};
