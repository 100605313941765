import React, { Component, ReactNode, ReactElement } from 'react';
import { useParams, Link } from 'react-router-dom';
import {
  Shed,
  ShedMap,
  OwnerId,
  ShedDetailMap,
  ShedUserDetail,
  ShedOwnerDetail,
  ShedOwnerMap,
} from 'comm/Shed';
import { ShedkeyMap, shedIcon } from 'components/Map';
import { Profile } from 'comm/Profile';
import { Item, ItemId } from 'comm/Items';
import { Marker, Popup } from 'react-leaflet';
import { ifexv, when } from 'comm/util';
import ShedSponsorPanel from './ShedSponsorPanel';

interface ShedPageProps {
  sheds: ShedMap;
  shedDetails: ShedDetailMap;
  profile: Profile;
  ownerDetailsMap?: ShedOwnerMap;
  createInvite: (shedId: string) => void;
}

interface ShowShedProps {
  shed: Shed & OwnerId;
  shedId: string;
  userDetails: ShedUserDetail[];
  profile?: Profile;
}

interface ShowShedState {
  highlighting: string | undefined;
}

class ShowShed extends Component<ShowShedProps, ShowShedState> {
  constructor(props: ShowShedProps) {
    super(props);

    this.state = {
      highlighting: undefined,
    };
  }

  highlight = (userId: string | undefined): void => {
    this.setState({ highlighting: userId });
  };

  openPopup(userId: string): (marker: Marker) => void {
    return (marker): void => {
      if (marker !== null) {
        if (
          marker.leafletElement !== undefined &&
          this.state.highlighting === userId
        ) {
          window.setTimeout(() => {
            marker.leafletElement.openPopup();
          });
        } else {
          window.setTimeout(() => {
            marker.leafletElement.closePopup();
          });
        }
      }
    };
  }

  render = (): ReactNode => {
    const { shed, userDetails, profile, shedId } = this.props;

    const items: (Item & ItemId)[] = userDetails.flatMap(({ items }) => items);

    const markers: ReactNode[] = userDetails
      .filter(({ user }) => user.publicLocation !== null)
      .map(({ user, items }, i) => (
        <Marker
          position={user.publicLocation}
          onmouseover={(): void => this.highlight(user.userId)}
          onmouseout={(): void => this.highlight(undefined)}
          ref={this.openPopup(user.userId)}
          key={i}
        >
          <Popup keepOpen={true}>
            <p>
              {user.publicName} shares {items.length} items.
              <br />
              Located near {user.publicAddress}
            </p>
            {when(user.userId === shed.owner)(() => (
              <p>Shed sponsor</p>
            ))}
            {when(profile !== undefined && profile.userId === user.userId)(
              () => (
                <p>This is you.</p>
              )
            )}
          </Popup>
        </Marker>
      ));

    return (
      <div className="ui shedkey secondary fluid container segment">
        <h1>{shed.name}</h1>
        <p>{shed.description}</p>
        <div className="ui two column grid">
          <div className="column">
            <div
              className="ui divided selection list"
              onMouseOut={(): void => this.highlight(undefined)}
            >
              {items.map((item, i) => (
                <div
                  key={i}
                  className={`item ${ifexv(
                    this.state.highlighting === item.owner
                  )('active')('')}`}
                  onTouchStart={(): void => this.highlight(item.owner)}
                  onMouseOver={(): void => this.highlight(item.owner)}
                >
                  {when(profile !== undefined && item.owner !== profile.userId)(
                    () => (
                      <div className="right floated content">
                        <Link to={`/items/request/${shedId}/${item.itemId}`}>
                          <button className="ui primary button" type="button">
                            Request
                          </button>
                        </Link>
                      </div>
                    )
                  )}
                  <div className="content">
                    <div className="header">{item.name}</div>
                    {item.description}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="column">
            <ShedkeyMap
              location={shed.location}
              editable={false}
              icon={shedIcon}
              popup="The approximate center of this shed's neighborhood.
                Nothing here, just a suggestion!"
            >
              {markers}
            </ShedkeyMap>
          </div>
        </div>
      </div>
    );
  };
}

type ShedPageMainProps = ShedPageProps & { shedId: string };

class ShedPageMain extends Component<ShedPageMainProps, {}> {
  render = (): ReactNode => {
    const {
      sheds,
      shedId,
      shedDetails,
      profile,
      ownerDetailsMap,
      createInvite,
    } = this.props;
    const shed = sheds[shedId];
    const userDetails = shedDetails[shedId];
    const ownerDetails = when<ShedOwnerDetail>(ownerDetailsMap !== undefined)(
      () => (ownerDetailsMap as ShedOwnerMap)[shedId]
    );

    if (shed !== undefined) {
      return (
        <div>
          <ShowShed
            shed={shed as Shed & OwnerId}
            userDetails={userDetails}
            profile={profile}
            shedId={shedId}
          />
          {when(ownerDetails !== undefined)(() => (
            <div className="ui shedkey fluid segment container">
              <h1 className="ui dividing header">Shed Sponsor Panel</h1>
              <ShedSponsorPanel
                shed={shed}
                shedDetails={userDetails}
                ownerDetails={ownerDetails as ShedOwnerDetail}
                createInvite={(): void => createInvite(shedId)}
              ></ShedSponsorPanel>
            </div>
          ))}
        </div>
      );
    }

    return <div></div>;
  };
}

const ShedPage: React.FC<ShedPageProps> = (
  props: ShedPageProps
): ReactElement => {
  const { shedId } = useParams();
  const { sheds, shedDetails, profile, ownerDetailsMap, createInvite } = props;

  return (
    <ShedPageMain
      sheds={sheds}
      shedId={shedId as string}
      shedDetails={shedDetails}
      profile={profile}
      ownerDetailsMap={ownerDetailsMap}
      createInvite={createInvite}
    />
  );
};

export default ShedPage;

export { ShedPage, ShowShed };
