import React, { Component, ReactNode } from 'react';
import { EditToggleButton } from 'components/ToggleButton';
import { Profile } from 'comm/Profile';
import { Location } from 'comm/Common';
import { EditableTextField } from 'components/Fields';
import { ShedkeyMap } from 'components/Map';
import { whenv } from 'comm/util';
import { Link } from 'react-router-dom';

interface ProfileFormProps {
  profile: Profile;
  onUpdate: (p: Profile) => void;
}

interface ProfileFormState {
  uiProfile: {
    publicName: string;
    publicLocation: Location;
    publicAddress: string;
    privateLocation?: Location;
    privateAddress: string;
  };
  editing: boolean;
}

class ProfileForm extends Component<ProfileFormProps, ProfileFormState> {
  constructor(props: ProfileFormProps) {
    super(props);

    const { profile: p } = props;

    const defaultLocation = {
      lat: 41.9692595,
      lng: -87.6554639,
    };

    this.state = {
      uiProfile: {
        publicName: p.publicName || '',
        publicLocation: p.publicLocation || defaultLocation,
        privateLocation: p.privateLocation,
        publicAddress: p.publicAddress || '',
        privateAddress: p.privateAddress || '',
      },
      editing: false,
    };
  }

  onToggle = (): void => {
    const { editing, uiProfile } = this.state;
    const { onUpdate } = this.props;

    this.setState({ editing: !editing });

    if (editing) {
      onUpdate(uiProfile);
    }
  };

  updateLocation = (publicLocation: Location): void =>
    this.setState({
      uiProfile: {
        ...this.state.uiProfile,
        publicLocation,
      },
    });

  render = (): ReactNode => {
    const { profile } = this.props;
    const { uiProfile, editing } = this.state;

    return (
      <div className="ui grid">
        <div className="two column row">
          <form className="ui form column">
            <EditToggleButton active={editing} onToggle={this.onToggle} />
            <div className="ui field">
              <EditableTextField
                editing={editing}
                label="Name"
                value={profile.publicName || ''}
              >
                <input
                  type="text"
                  value={uiProfile.publicName || ''}
                  onChange={(ev): void =>
                    this.setState({
                      uiProfile: { ...uiProfile, publicName: ev.target.value },
                    })
                  }
                />
              </EditableTextField>
            </div>
            <div className="ui field">
              <EditableTextField
                editing={editing}
                label="Public Address"
                value={profile.publicAddress || ''}
              >
                <input
                  type="text"
                  value={uiProfile.publicAddress || ''}
                  onChange={(ev): void =>
                    this.setState({
                      uiProfile: {
                        ...uiProfile,
                        publicAddress: ev.target.value,
                      },
                    })
                  }
                />
              </EditableTextField>
            </div>
            <div className="ui field">
              <EditableTextField
                editing={editing}
                label="Private Address"
                value={profile.privateAddress || ''}
              >
                <input
                  type="text"
                  value={uiProfile.privateAddress || ''}
                  onChange={(ev): void =>
                    this.setState({
                      uiProfile: {
                        ...uiProfile,
                        privateAddress: ev.target.value,
                      },
                    })
                  }
                />
              </EditableTextField>
            </div>
          </form>
          <div className="column">
            <Link className="ui primary button" to="/account/items">
              Manage your items
            </Link>
          </div>
        </div>
        <div className="one column fluid row">
          <div className="column">
            {whenv(editing)(
              <label>Drag to reposition your public location</label>
            )}
            <ShedkeyMap
              location={this.state.uiProfile.publicLocation}
              updateLocation={this.updateLocation}
              editable={editing}
              popup={<p>This is you!</p>}
            />
          </div>
          {whenv(editing)(
            <EditToggleButton active={editing} onToggle={this.onToggle} />
          )}
        </div>
      </div>
    );
  };
}

export default ProfileForm;
