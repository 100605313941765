import React, { Component, ChangeEvent, ReactNode } from 'react';
import { MutFieldProps } from 'comm/Common';
import { Shed, ShedOwnerDetail, ShedUserDetail } from 'comm/Shed';
import { ifex } from 'comm/util';

interface ShedSponsorPanelProps {
  shed: Shed;
  ownerDetails: ShedOwnerDetail;
  shedDetails: ShedUserDetail[];
  createInvite: () => void;
}

interface ShedSponsorPanelState {
  shedName: string;
}

export default class ShedSponsorPanel extends Component<
  ShedSponsorPanelProps,
  ShedSponsorPanelState
> {
  constructor(props: ShedSponsorPanelProps) {
    super(props);

    this.state = {
      shedName: '',
    };
  }

  evUpdate: (field: string) => (ev: ChangeEvent<HTMLInputElement>) => void = (
    field
  ) => (ev): void => {
    let update = {};
    if (ev === null) {
      return;
    }
    const value: string = ev.target.value;

    if (field === 'shedName') {
      update = { shedName: value };
    }

    this.setState(update);
  };

  fieldProps: (field: string) => MutFieldProps<HTMLInputElement> = (field) => {
    let value = '';
    if (field === 'shedName') {
      value = this.state.shedName;
    }
    return {
      value,
      onChange: this.evUpdate(field),
    };
  };

  render = (): ReactNode => {
    const { ownerDetails, createInvite } = this.props;

    return (
      <div className="ui shedkey secondary fluid container segment">
        <h2 className="ui header">Your invitations</h2>
        {ifex(ownerDetails.invitations.length > 0)(() => (
          <div>
            <p>
              Anyone with the sequence of words below is able to join your shed.
              Consider providing this to local groups on social media,
              neighborhood email groups, and friends in the neighborood.
            </p>
            <div className="ui list">
              {ownerDetails.invitations.map((invite, i) => (
                <div className="item" key={i}>
                  {invite.code.join(' ')}
                </div>
              ))}
            </div>
          </div>
        ))(() => (
          <div>
            <p>You don&apos;t have any invitations open yet.</p>
            <button
              className="ui primary button"
              type="button"
              onClick={(): void => createInvite()}
            >
              Create invite
            </button>
          </div>
        ))}
      </div>
    );
  };
}
