import React, { Component, ReactNode } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { ifex } from 'comm/util';
import profileApi from 'comm/Profile';
import { emptyAuthState } from 'comm/Auth';

interface VerificationProps {
  verifyId: string;
  userId: string;
}

interface VerificationState {
  done: boolean;
}

class VerificationPage extends Component<VerificationProps, VerificationState> {
  constructor(props: VerificationProps) {
    super(props);

    this.state = {
      done: false,
    };
  }

  componentDidMount = (): void => {
    const { userId, verifyId } = this.props;

    profileApi.verifyEmail(userId, verifyId, emptyAuthState).then(() => {
      this.setState({ done: true });
    });
  };

  render = (): ReactNode =>
    ifex<ReactNode>(this.state.done)(() => <Redirect to={'/login'} />)(() => (
      <div>Verifying...</div>
    ));
}

const Verification: React.FC = () => {
  const { userId, verifyId } = useParams();

  return <VerificationPage userId={userId} verifyId={verifyId} />;
};

export default Verification;
