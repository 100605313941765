import React, { Component, ReactNode, ReactElement } from 'react';
import { ShedMap } from 'comm/Shed';
import { ifexv, whenv } from 'comm/util';
import { Link } from 'react-router-dom';
import { ReactAppContext } from 'components/AppContext';

export interface UserShedsProps {
  sheds: ShedMap;
}

export class UserSheds extends Component<UserShedsProps, {}> {
  shouldComponentUpdate = (nextProps: UserShedsProps): boolean =>
    typeof nextProps.sheds.forEach === 'function';

  render = (): ReactNode => {
    const { sheds } = this.props;

    const shedRows = (userId: string | undefined): ReactElement[] =>
      Object.keys(sheds).map((shedId) => {
        return (
          <div className="item" key={shedId}>
            <div className="right floated content">
              <Link to={`/sheds/${shedId}`} className="ui primary button">
                Go
              </Link>
            </div>
            <div className="content">
              <div className="header">
                {sheds[shedId].name}
                {whenv(
                  userId !== undefined &&
                    sheds[shedId].owner === (userId as string)
                )(<span>&nbsp;(Owner)</span>)}
              </div>
              {sheds[shedId].description}
            </div>
          </div>
        );
      });

    return (
      <ReactAppContext.Consumer>
        {(context): ReactNode => (
          <div>
            {ifexv(Object.keys(sheds).length === 0)(
              <p>You don&apos;t belong to any sheds yet.</p>
            )(
              <div className="ui divided list">
                {shedRows(context.profile.state.userId)}
              </div>
            )}
            <div className="ui buttons">
              <Link to="/sheds/create">
                <button type="button" className="ui primary button">
                  Create a Shed
                </button>
              </Link>
              <div className="or"></div>
              <Link to="/sheds/join">
                <button type="button" className="ui primary button">
                  Join from Invite
                </button>
              </Link>
            </div>
          </div>
        )}
      </ReactAppContext.Consumer>
    );
  };
}
