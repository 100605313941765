import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import logoBlue from 'assets/images/logo-blue.svg';

const LoggedOutMenu = (): JSX.Element => (
  <div>
    <Link to="/login">
      <button className="ui basic button" type="button">
        Sign in
      </button>
    </Link>
    <Link to="/signup">
      <button className="ui basic button" type="button">
        Get Started
      </button>
    </Link>
  </div>
);

interface HeaderProps {
  loggedIn: boolean;
}

export default function Header(props: HeaderProps): ReactElement {
  let loggedMenu;
  if (!props.loggedIn) {
    loggedMenu = <LoggedOutMenu />;
  } else {
    loggedMenu = (
      <Link to="/account">
        <button className="ui basic button" type="button">
          My account
        </button>
      </Link>
    );
  }

  return (
    <div id="header-container" className="ui grid container">
      <div className="two column row">
        <div className="left floated left aligned middle aligned column">
          <Link to="/">
            <div className="svg">
              <img src={logoBlue} alt="Shedkey logo" />
            </div>
          </Link>
        </div>
        <div className="ui right aligned middle aligned column">
          {loggedMenu}
        </div>
      </div>
    </div>
  );
}
