import { apiRoute, SiteResult, ManyRequest, ApiRoute } from './Common';
import { AuthState, AuthResult, makeAuthRequest } from './Auth';
import { PublicUser } from './Shed';

export interface Item {
  name: string;
  description: string;
}

export interface ItemId {
  itemId: string;
  owner: string;
}

export interface BorrowReq {
  toUser: string;
  itemId: string;
  text: string;
}

export interface Message {
  from: string;
  to: string;
  text: string;
  openTime: Date;
}

export interface BorrowInfo {
  fromUser: PublicUser;
  item: Item & ItemId;
  messages: [Message];
}

export interface BorrowMessage {
  borrowId: string;
  message: string;
}

const ApiRoutes = {
  createItem: (data: Item): ApiRoute<Item, SiteResult<string>> =>
    apiRoute('/items', 'POST', data),
  createMany: (
    data: Item[]
  ): ApiRoute<ManyRequest<Item>, SiteResult<string[]>> =>
    apiRoute('/items/many', 'POST', {
      many: data,
    }),
  items: (): ApiRoute<null, SiteResult<(Item & ItemId)[]>> =>
    apiRoute('/items', 'GET', null),
  request: (req: BorrowReq): ApiRoute<BorrowReq, SiteResult<string>> =>
    apiRoute('/borrow', 'POST', req),
  respondInfo: (reqId: string): ApiRoute<null, SiteResult<BorrowInfo>> =>
    apiRoute(`/borrow/${reqId}`, 'GET', null),
  sendMessage: (
    borrowMessage: BorrowMessage
  ): ApiRoute<BorrowMessage, SiteResult<string>> =>
    apiRoute(`/borrow/respond`, 'POST', borrowMessage),
};

function process<T>(): (sr: AuthResult<SiteResult<T>>) => AuthResult<T> {
  return (sr): AuthResult<T> => ({ state: sr.state, data: sr.data.result });
}

function createItem(item: Item, s: AuthState): Promise<AuthResult<string>> {
  return makeAuthRequest(ApiRoutes.createItem(item))(s).then(process());
}

function respondInfo(
  reqId: string,
  s: AuthState
): Promise<AuthResult<BorrowInfo>> {
  return makeAuthRequest(ApiRoutes.respondInfo(reqId))(s).then(process());
}

function sendMessage(
  borrowMessage: BorrowMessage,
  s: AuthState
): Promise<AuthResult<string>> {
  return makeAuthRequest(ApiRoutes.sendMessage(borrowMessage))(s).then(
    process()
  );
}

function createMany(
  items: Item[],
  s: AuthState
): Promise<AuthResult<string[]>> {
  return makeAuthRequest(ApiRoutes.createMany(items))(s).then(process());
}

function items(s: AuthState): Promise<AuthResult<(Item & ItemId)[]>> {
  return makeAuthRequest(ApiRoutes.items())(s).then(process());
}

function requestItem(
  itemId: string,
  toUser: string,
  text: string,
  s: AuthState
): Promise<AuthResult<string>> {
  return makeAuthRequest(
    ApiRoutes.request({
      itemId,
      toUser,
      text,
    })
  )(s).then(process());
}

export default {
  createItem,
  createMany,
  items,
  requestItem,
  respondInfo,
  sendMessage,
};
