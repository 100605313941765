import React, { Component, ReactNode } from 'react';
import { ReactAppContext } from 'components/AppContext';
import ProfileForm from './ProfileForm';
import { UserSheds } from './UserSheds';
import { Profile } from 'comm/Profile';

interface AccountState {
  editing: boolean;
}

interface AccountProps {
  onLogout: () => Promise<void>;
}

class Account extends Component<AccountProps, AccountState> {
  constructor(props: AccountProps) {
    super(props);

    this.state = {
      editing: false,
    };
  }

  warnings = (profile: Profile): ReactNode[] => {
    const res = [];
    if (!profile.verifiedEmail) {
      res.push(
        <div className="ui warning message" key="email warning">
          We have sent you a verification email, please click the link!
        </div>
      );
    }
    return res;
  };

  render = (): ReactNode => {
    const { onLogout } = this.props;

    return (
      <ReactAppContext.Consumer>
        {(appState): ReactNode => (
          <div className="ui shedkey secondary container segment">
            <h1>Your profile</h1>
            <button
              type="button"
              className="ui basic button"
              onClick={onLogout}
            >
              Logout
            </button>
            {this.warnings(appState.profile.state)}
            <div className="shedkey secondary ui segment">
              <ProfileForm
                profile={appState.profile.state}
                onUpdate={appState.profile.update}
              />
              <div>
                <h2>Your sheds</h2>
                <UserSheds sheds={appState.sheds} />
              </div>
            </div>
          </div>
        )}
      </ReactAppContext.Consumer>
    );
  };
}

export default Account;

export { Account };
