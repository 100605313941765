import React, { Component, ReactNode } from 'react';
import pinIcon from 'assets/images/pin.svg';
import bannerMap from 'assets/images/banner-map.svg';

interface NeighborPin {
  question: string;
  answer: string;
  top: number;
  left: number;
}

const neighborPin = (
  question: string,
  answer: string,
  top: number,
  left: number
): NeighborPin => ({
  question,
  answer,
  top,
  left,
});

const neighborPins: NeighborPin[] = [
  neighborPin(
    'I need a hiking backpack for a camping trip this weekend...',
    'Your neighbor Sam P has one 0.4 miles away',
    25,
    45
  ),
  neighborPin(
    "I'm looking for a drill for repairing a bookcase tomorrow",
    'Your neighbor Sally T has one 0.2 miles away',
    10,
    30
  ),
  neighborPin(
    'I need a lawn aerator for some yard work Saturday',
    'Bob S. has one 0.6 miles away',
    60,
    30
  ),
];

const pinStyle = (top: number, left: number): React.CSSProperties => ({
  top: `${top}%`,
  left: `${left}`,
  width: '160px',
  position: 'absolute',
});

const pinAndPop: React.FC<NeighborPin> = (pin: NeighborPin) => (
  <div style={pinStyle(pin.top, pin.left)}>
    <div className="ui pointing below message label">{pin.answer}</div>
    <img
      src={pinIcon}
      alt="An icon representing a map pin, that moves around the map"
      style={{ position: 'relative', left: '70px', width: '20px' }}
    />
  </div>
);

interface SplashMapState {
  index: number;
  interval: number | undefined;
}

class SplashMap extends Component<{}, SplashMapState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      index: 0,
      interval: undefined,
    };
  }

  componentDidMount = (): void =>
    this.setState({
      interval: window.setInterval(
        () =>
          this.setState({
            index: (this.state.index + 1) % neighborPins.length,
          }),
        5000
      ),
    });

  componentWillUnmount = (): void => {
    if (this.state.interval !== undefined) {
      window.clearInterval(this.state.interval as number);
    }
    this.setState({ interval: undefined });
  };

  render = (): ReactNode => (
    <div>
      <div className="massive pusher" />
      <div className="ui middle aligned two column stackable centered grid container">
        <div className="ui column">
          <h2 className="ui shedkey foreground header">
            {neighborPins[this.state.index].question}
          </h2>
        </div>
        <div className="ui column svg">
          <img
            src={bannerMap}
            alt="An abstract representation of a street map"
          />
          {pinAndPop(neighborPins[this.state.index])}
        </div>
      </div>
    </div>
  );
}

export default SplashMap;
