import React, { Component, ChangeEvent, ReactNode, ReactElement } from 'react';
import { MutFieldProps } from 'comm/Common';
import { Profile } from 'comm/Profile';
import { ShedDetailMap, ShedUserDetail, PublicUser } from 'comm/Shed';
import { Redirect, useParams, Link } from 'react-router-dom';
import { ifex, when } from 'comm/util';
import _ from 'lodash';
import { Item, ItemId } from 'comm/Items';

interface ItemRequestPageProps {
  onComplete: (itemId: string, toUser: string, text: string) => void;
  profile: Profile;
  shedDetails: ShedDetailMap;
}

interface ItemRequestRouteProps {
  shedId: string;
  itemId: string;
}

interface ItemRequestPageState {
  message: string | undefined;
  toUser: PublicUser | undefined;
  sent: boolean;
}

const baseMessage = (
  toolName: string,
  toName: string,
  fromName: string,
  userLocation: string
): string =>
  `Hello ${toName},

I would like to borrow your ${toolName}. I promise I will return it in perfect condition.

Thank you!

${fromName}
${userLocation}
`;

class ItemRequestPage2 extends Component<
  ItemRequestPageProps & ItemRequestRouteProps,
  ItemRequestPageState
> {
  constructor(props: ItemRequestPageProps & ItemRequestRouteProps) {
    super(props);

    const userItem = this.userItemRequested();

    this.state = {
      message: undefined,
      toUser: undefined,
      sent: false,
    };

    when(userItem !== undefined)(() => {
      const { profile } = props;
      const [user, item] = userItem as [PublicUser, Item];

      this.state = {
        message: baseMessage(
          item.name,
          user.publicName,
          profile.publicName !== undefined
            ? (profile.publicName as string)
            : '',
          profile.privateLocation !== undefined
            ? (profile.privateAddress as string)
            : ''
        ),
        toUser: user,
        sent: false,
      };
    });
  }

  // Need the user providing the request, and the item requested.
  userItemRequested = (): [PublicUser, Item & ItemId] | undefined => {
    const { itemId, shedId, shedDetails } = this.props;
    const shedDetail: ShedUserDetail[] = shedDetails[shedId];

    const requestedFrom = _.find<ShedUserDetail>(shedDetail, (o): boolean =>
      _.some(o.items, (i) => i.itemId === itemId)
    );

    if (requestedFrom === undefined) {
      return undefined;
    }

    return [
      (requestedFrom as ShedUserDetail).user,
      _.find(
        (requestedFrom as ShedUserDetail).items,
        (i) => i.itemId === itemId
      ) as Item & ItemId,
    ];
  };

  evUpdate: (
    field: string
  ) => (ev: ChangeEvent<HTMLTextAreaElement>) => void = (field) => (
    ev
  ): void => {
    let update = {};
    if (ev === null) {
      return;
    }
    const value: string = ev.target.value;

    if (field === 'message') {
      update = { message: value };
    }

    this.setState(update);
  };

  fieldProps: (field: string) => MutFieldProps<HTMLTextAreaElement> = (
    field
  ) => {
    let value = '';
    if (field === 'message') {
      value = this.state.message as string;
    }
    return {
      value,
      onChange: this.evUpdate(field),
    };
  };

  onComplete = (): void => {
    this.setState({ sent: true });

    const itemUser = this.userItemRequested();

    if (itemUser !== undefined) {
      const [user, itemId] = itemUser as [PublicUser, Item & ItemId];
      this.props.onComplete(
        itemId.itemId,
        user.userId,
        this.state.message as string
      );
    }
  };

  render = (): ReactNode =>
    ifex<ReactNode>(this.state.message === undefined)(() => (
      <Redirect to="/" />
    ))(() =>
      ifex<ReactNode>(this.state.sent)(() => (
        <div className="ui shedkey secondary container segment">
          <div className="ui segment">
            <pre>{this.state.message as string}</pre>
            <div className="ui message label">Your message has been sent!</div>
            <div>
              <Link to={`/sheds/${this.props.shedId}`}>
                <div className="ui primary button">
                  <i className="ui left arrow icon" />
                  Back
                </div>
              </Link>
            </div>
          </div>
        </div>
      ))(() => (
        <div className="ui shedkey secondary container segment">
          Compose message to {(this.state.toUser as PublicUser).publicName}
          <div className="ui form">
            <div className="field">
              <textarea {...this.fieldProps('message')} />
            </div>
            <button className="ui primary button" onClick={this.onComplete}>
              <i className="ui send icon" />
              &nbsp;Send
            </button>
            <Link
              className="ui primary button"
              to={`/sheds/${this.props.shedId}`}
            >
              <i className="ui left arrow icon" />
              Back
            </Link>
          </div>
        </div>
      ))
    );
}

const ItemRequestPage: React.FC<ItemRequestPageProps> = (
  props: ItemRequestPageProps
) => {
  const { shedId, itemId } = useParams();

  return ifex<ReactElement>(
    shedId === undefined || itemId === undefined
  )(() => <Redirect to="/" />)(() => (
    <ItemRequestPage2
      shedId={shedId as string}
      itemId={itemId as string}
      {...props}
    />
  ));
};

export default ItemRequestPage;
