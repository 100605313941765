import React, { Component, ReactNode } from 'react';
import { ifexv } from 'comm/util';

export interface FieldProps {
  label: string;
  editing: boolean;
  value: string;
}

interface FieldState {
  value: string;
}

export class EditableTextField extends Component<FieldProps, FieldState> {
  constructor(props: FieldProps) {
    super(props);

    this.state = {
      value: props.value,
    };
  }

  render = (): ReactNode => {
    const { label, editing, children, value = '' } = this.props;

    const fieldVal = ifexv<string>(value !== '')(value)('Not set');

    const vfield = ifexv<ReactNode>(editing)(children)(<span>{fieldVal}</span>);

    return (
      <div className="ui field">
        <label>{label}</label>
        {vfield}
      </div>
    );
  };
}
