import React, { Component, ReactNode, ReactElement } from 'react';
import '../assets/semantic/dist/semantic.css';
import '../assets/sass/site.main.scss';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  Link,
} from 'react-router-dom';
import Header from 'components/Header';
import Footer from 'components/Footer';
import BorrowRespondPage from './BorrowRespondPage';
import Splash from './Splash';
import ShedPage from './ShedPage';
import ItemRequestPage from './ItemRequestPage';
import Account from './account/Account';
import CreateShed from './account/CreateShed';
import ManageItems from './ManageItems';
import { About, Terms, Contact } from './About';
import { Login, Signup } from 'components/Auth';
import { ReactAppContext } from 'components/AppContext';
import Auth, {
  emptyAuthState,
  LoginData,
  AuthState,
  AuthResult,
} from 'comm/Auth';

import ItemApi, { Item, ItemId } from 'comm/Items';
import ShedApi, {
  Shed,
  ShedMap,
  ShedDetailMap,
  ShedOwnerMap,
  ShedOwnerDetail,
  ShedProvisional,
} from 'comm/Shed';
import ProfileApi, { Profile, emptyProfile } from 'comm/Profile';
import { ifexv, when, ifex } from 'comm/util';
import JoinInvitePage, { JoinInvitePageUnauth } from './JoinInvite';
import Verification from './account/Verification';

interface LastErrorStatus {
  login?: number;
  signup?: number;
}

interface AppState {
  auth: AuthState;
  profile: Profile;
  items: (Item & ItemId)[];
  sheds: ShedMap;
  shedDetails: ShedDetailMap;
  debug: boolean | undefined;
  ownerDetailsMap: ShedOwnerMap;
  shedProvisional?: ShedProvisional;
  lastError: LastErrorStatus;
}

interface AppProps {
  debug: boolean;
}

interface AppProps {
  debug: boolean;
}

const emptyAppState: AppState = {
  auth: emptyAuthState,
  profile: emptyProfile,
  items: [],
  sheds: {},
  shedDetails: {},
  debug: false,
  ownerDetailsMap: {},
  lastError: {},
};

interface ShedRoutesProps {
  sheds: ShedMap;
  shedDetails: ShedDetailMap;
  createShed: (s: Shed) => void;
  joinShedInvite: (code: string) => void;
  profile: Profile;
  ownerDetailsMap?: ShedOwnerMap;
  createInvite: (shedId: string) => void;
}

const ShedRoutes: React.FC<ShedRoutesProps> = (props: ShedRoutesProps) => {
  const {
    createShed,
    sheds,
    joinShedInvite,
    shedDetails,
    profile,
    ownerDetailsMap,
    createInvite,
  } = props;
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.url}/create`}>
        <CreateShed onComplete={createShed} />
      </Route>
      <Route path={`${match.url}/join`}>
        {<JoinInvitePage onComplete={joinShedInvite} />}
      </Route>
      <Route path={`${match.url}/:shedId`}>
        <div>
          <ShedPage
            sheds={sheds}
            shedDetails={shedDetails}
            profile={profile}
            ownerDetailsMap={ownerDetailsMap}
            createInvite={createInvite}
          />
        </div>
      </Route>
    </Switch>
  );
};

interface JoinRoutesProps {
  onInviteCodeSubmit: (c: string) => Promise<void>;
  shedProvisional?: ShedProvisional;
  loginJoin?: (d: LoginData) => Promise<void>;
  signupJoin?: (d: LoginData) => Promise<void>;
  doneRedirect?: ReactElement;
}

const JoinRoutes: React.FC<JoinRoutesProps> = (props: JoinRoutesProps) => {
  const match = useRouteMatch();

  const {
    onInviteCodeSubmit,
    shedProvisional,
    doneRedirect,
    loginJoin,
    signupJoin,
  } = props;

  return (
    <Switch>
      <Route path={`${match.url}`}>
        <JoinInvitePageUnauth
          onComplete={onInviteCodeSubmit}
          shedProvisional={shedProvisional}
          loginJoin={loginJoin}
          signupJoin={signupJoin}
          doneRedirect={doneRedirect}
        />
      </Route>
      <Route path={`${match.url}/:inviteCode`}></Route>
    </Switch>
  );
};

interface ItemRoutesProps {
  onCreateRequest: (
    itemId: string,
    userId: string,
    text: string
  ) => Promise<void>;
  shedDetails: ShedDetailMap;
  profile: Profile;
  authState: AuthState;
}

const ItemRoutes: React.FC<ItemRoutesProps> = (props: ItemRoutesProps) => {
  const match = useRouteMatch();
  const { profile, shedDetails, onCreateRequest } = props;

  return (
    <Switch>
      <Route path={`${match.url}/request/:shedId/:itemId`}>
        <ItemRequestPage
          onComplete={onCreateRequest}
          shedDetails={shedDetails}
          profile={profile}
        />
      </Route>
    </Switch>
  );
};

type ShedOwnerDetailPair = [string, ShedOwnerDetail];

class App extends Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);
    this.state = emptyAppState;

    const prior = localStorage.getItem('shedkey');
    if (prior) {
      const priorState: AppState = JSON.parse(prior);
      if (priorState.auth && priorState.profile) {
        this.state = {
          ...this.state,
          ...priorState,
        };
        this.loadProfile();
      }
    }
    this.localStorage(this.state);
  }

  loadProfile = (s: AuthState = this.state.auth): Promise<void> =>
    Auth.initState(s)
      .then(([profile, items, sheds, shedDetails]) => {
        this.setState({
          auth: s,
          profile,
          items,
          sheds,
          shedDetails,
        });

        this.localStorage({
          ...this.state,
          auth: s,
          profile,
          items,
          sheds,
          shedDetails,
        });

        const ownedKeys: string[] = Object.keys(sheds).filter(
          (shedId) => sheds[shedId].owner === profile.userId
        );

        if (ownedKeys.length > 0) {
          return Promise.all(
            ownedKeys.map((shedId) =>
              ShedApi.shedOwnerDetail(shedId, s).then(
                (r): ShedOwnerDetailPair => [shedId, r.data]
              )
            )
          );
        }

        return [] as [string, ShedOwnerDetail][];
      })
      .then((ownerDetailsList): void => {
        const ownerDetailsMap: ShedOwnerMap = ownerDetailsList.reduce(
          (acc, [shedId, detail]) => ({ ...acc, [shedId]: detail }),
          {}
        );

        this.setState({
          ownerDetailsMap,
        });
      })
      .catch((err) => {
        console.log('Handling error from initstate');
        console.log(err);
        this.setState(emptyAppState);
        this.localStorage(emptyAppState);
      });

  localStorage = (state: AppState): void => {
    localStorage.setItem('shedkey', JSON.stringify(state));
  };

  isLoggedIn: () => boolean = () => this.state.auth.token !== null;

  updateProfile = (up: Profile): void => {
    this.setState({ profile: up });
    ProfileApi.editProfile(up, this.state.auth);
  };

  onAddItem = (item: Item): void => {
    this.setState({
      items: [
        ...this.state.items,
        {
          ...item,
          /// The logged in user won't have its own ids for these items in state,
          /// until next refresh, but that's ok, only other users need them.
          owner: (null as unknown) as string,
          itemId: (null as unknown) as string,
        },
      ],
    });
    ItemApi.createItem(item, this.state.auth);
  };

  resetAll = (): void => {
    this.setState(emptyAppState);
    this.localStorage(emptyAppState);
  };

  login = (data: LoginData): Promise<void> =>
    Auth.login(data, this.state.auth)
      .then((s) => {
        if (s !== null) {
          return this.loadProfile(s.state);
        } else {
          return Promise.reject(null);
        }
      })
      .catch((s) => {
        this.setState({
          lastError: {
            ...this.state.lastError,
            login: s.status,
          },
        });
      });

  signup = (data: LoginData): Promise<AuthState> =>
    Auth.signup(data, this.state.auth)
      .then(
        (): Promise<AuthResult<string>> => {
          this.setState({
            lastError: { ...this.state.lastError, signup: undefined },
          });
          return Auth.login(data, this.state.auth);
        }
      )
      .then(
        (loginres): Promise<AuthState> => {
          this.setState({
            auth: loginres.state,
          });

          return ProfileApi.initProfile(loginres.state).then(() => {
            this.loadProfile(loginres.state);
            return loginres.state;
          });
        }
      )
      .catch((s) => {
        this.setState({
          lastError: { ...this.state.lastError, signup: s.status },
        });
        return emptyAuthState;
      });

  loginJoin = (data: LoginData, code: string): Promise<void> =>
    Auth.login(data, this.state.auth)
      .then((s) => ShedApi.joinFromInviteId(code, s.state))
      .then((s) => this.loadProfile(s.state))
      .then(undefined);

  signupJoin = (data: LoginData, code: string): Promise<void> =>
    this.signup(data)
      .then((s) => ShedApi.joinFromInviteId(code, s))
      .then((s) => this.loadProfile(s.state))
      .then(undefined);

  logout = (): Promise<void> =>
    Auth.logout(this.state.auth).then(this.resetAll);

  loggedInOrRoot = (allowed: ReactNode): ReactNode =>
    ifexv<ReactNode>(this.isLoggedIn())(allowed)(<Redirect to="/" />);

  notLoggedInOrAccount = (allowed: ReactNode): ReactNode =>
    ifexv<ReactNode>(!this.isLoggedIn())(allowed)(<Redirect to="/account" />);

  createShed = (shed: Shed): Promise<void> =>
    ShedApi.create(shed, this.state.auth)
      .then(() => this.loadProfile())
      .then(undefined);

  joinShedInvite = (code: string): Promise<void> =>
    ShedApi.joinFromInvite(code, this.state.auth).then(() =>
      this.loadProfile()
    );

  joinShedInviteUnauth = (code: string): Promise<void> => {
    return ShedApi.joinFromInviteUnauth(code, this.state.auth).then(
      (result: AuthResult<ShedProvisional>) => {
        return this.setState({ shedProvisional: result.data });
      }
    );
  };

  requestItem = (itemId: string, toUser: string, text: string): Promise<void> =>
    ItemApi.requestItem(itemId, toUser, text, this.state.auth).then();

  createInvite = (shedId: string): Promise<void> =>
    ShedApi.createInvite(shedId, this.state.auth)
      .then(() => ShedApi.shedOwnerDetail(shedId, this.state.auth))
      .then((res: AuthResult<ShedOwnerDetail>): void => {
        this.setState({
          ownerDetailsMap: {
            ...this.state.ownerDetailsMap,
            [shedId]: res.data,
          },
        });
      });

  borrowRespondSend = (borrowId: string, message: string): Promise<void> =>
    ItemApi.sendMessage({ borrowId, message }, this.state.auth).then();

  render = (): ReactNode => {
    const {
      auth,
      profile,
      items,
      sheds,
      shedDetails,
      ownerDetailsMap,
      shedProvisional,
      lastError,
    } = this.state;

    const context = {
      profile: {
        state: profile,
        update: this.updateProfile,
      },
      sheds,
    };

    return (
      <Router>
        <div className="ui shedkey background">
          <div className="page-container">
            <Header loggedIn={this.isLoggedIn()} />
            <ReactAppContext.Provider value={context}>
              <Switch>
                <Route path="/login">
                  {this.notLoggedInOrAccount(
                    <Login
                      serverError={lastError.login}
                      onSubmit={this.login}
                    />
                  )}
                </Route>
                <Route path="/signup">
                  {this.notLoggedInOrAccount(
                    <Signup
                      serverError={lastError.signup}
                      onSubmit={this.signup}
                    />
                  )}
                </Route>
                <Route path="/account/items">
                  {this.loggedInOrRoot(
                    <ManageItems items={items} onAdd={this.onAddItem} />
                  )}
                </Route>
                <Route path="/sheds">
                  {this.loggedInOrRoot(
                    <ShedRoutes
                      joinShedInvite={this.joinShedInvite}
                      createShed={this.createShed}
                      sheds={sheds}
                      shedDetails={shedDetails}
                      profile={profile}
                      ownerDetailsMap={ownerDetailsMap}
                      createInvite={this.createInvite}
                    />
                  )}
                </Route>
                <Route path="/items">
                  {this.loggedInOrRoot(
                    <ItemRoutes
                      authState={auth}
                      onCreateRequest={this.requestItem}
                      profile={profile}
                      shedDetails={shedDetails}
                    />
                  )}
                </Route>
                <Route path="/respond/:borrowId">
                  <BorrowRespondPage
                    authState={this.state.auth}
                    onComplete={this.borrowRespondSend}
                  />
                </Route>
                <Route path="/account">
                  {this.loggedInOrRoot(<Account onLogout={this.logout} />)}
                </Route>
                <Route path="/join">
                  <JoinRoutes
                    onInviteCodeSubmit={(c: string): Promise<void> =>
                      this.joinShedInviteUnauth(c).then(undefined)
                    }
                    shedProvisional={this.state.shedProvisional}
                    loginJoin={(d: LoginData): Promise<void> =>
                      ifex<Promise<void>>(
                        this.state.shedProvisional !== undefined
                      )(
                        (): Promise<void> =>
                          this.loginJoin(
                            d,
                            (shedProvisional as ShedProvisional).inviteCode
                          )
                      )(() => Promise.resolve(undefined))
                    }
                    signupJoin={(d: LoginData): Promise<void> =>
                      ifex<Promise<void>>(
                        this.state.shedProvisional !== undefined
                      )(() =>
                        this.signupJoin(
                          d,
                          (shedProvisional as ShedProvisional).inviteCode
                        )
                      )(() => Promise.resolve(undefined))
                    }
                    doneRedirect={when<ReactElement | undefined>(
                      this.state.shedProvisional !== undefined
                    )(() => {
                      const { shedProvisional, shedDetails } = this.state;
                      const sp = shedProvisional as ShedProvisional;
                      return when<ReactElement | undefined>(
                        sp.shedId in shedDetails
                      )(() => (
                        <Link to={`/sheds/${sp.shedId}`}>
                          <button className="ui primary button">Go</button>
                        </Link>
                      ));
                    })}
                  />
                </Route>
                <Route path="/profile/verify/:userId/:verifyId">
                  <Verification />
                </Route>
                <Route path="/about">
                  <About />
                </Route>
                <Route path="/terms">
                  <Terms />
                </Route>
                <Route path="/contact">
                  <Contact />
                </Route>
                <Route path="/">
                  <Splash />
                </Route>
              </Switch>
            </ReactAppContext.Provider>
            {when<ReactNode>(this.props.debug)(() => (
              <div
                className="ui center aligned container"
                style={{ background: 'transparent' }}
              >
                <button
                  type="button"
                  className="ui red button"
                  onClick={(): void => {
                    console.log(this.state);
                    this.setState({ debug: true });
                  }}
                >
                  State
                </button>
              </div>
            ))}
            {when(this.state.debug === true)(() => (
              <div className="ui segment inverted">
                <pre>{JSON.stringify(this.state, undefined, 2)}</pre>
              </div>
            ))}
            <div id="pre-footer"></div>
            <Footer />
          </div>
        </div>
      </Router>
    );
  };
}

export default App;
