import React, { Component, ChangeEvent, ReactNode } from 'react';
import { MutFieldProps } from 'comm/Common';
import { Item, ItemId } from 'comm/Items';
import { ItemsTable } from 'components/ItemTable';

interface ItemFormState {
  name: string;
  description: string;
}

interface ItemFormProps {
  onAdd: (item: ItemFormState) => void;
}

class ItemForm extends Component<ItemFormProps, ItemFormState> {
  constructor(props: ItemFormProps) {
    super(props);

    this.state = {
      name: '',
      description: '',
    };
  }

  evUpdate: (field: string) => (ev: ChangeEvent<HTMLInputElement>) => void = (
    field
  ) => (ev): void => {
    let update = {};
    if (ev === null) {
      return;
    } else {
      const value: string = ev.target.value;

      if (field === 'name') {
        update = { name: value };
      } else if (field === 'description') {
        update = { description: value };
      }

      this.setState(update);
    }
  };

  fieldProps: (field: string) => MutFieldProps<HTMLInputElement> = (field) => {
    let value = '';
    if (field === 'name') {
      value = this.state.name;
    } else if (field === 'description') {
      value = this.state.description;
    }

    return {
      value,
      onChange: this.evUpdate(field),
    };
  };

  onAdd = (): void => {
    this.props.onAdd(this.state);
    this.setState({
      name: '',
      description: '',
    });
  };

  render = (): ReactNode => {
    return (
      <form className="ui fluid form segment grid container">
        <div className="row">
          <div className="six wide column field">
            <label>Name</label>
            <input
              type="text"
              className="ui input"
              {...this.fieldProps('name')}
            />
          </div>
          <div className="eight wide column field">
            <label>Description</label>
            <input
              type="text"
              className="ui input"
              {...this.fieldProps('description')}
            />
          </div>
          <div className="ui two wide right aligned column field">
            <label>&nbsp;</label>
            <button
              className="ui primary button field"
              type="button"
              onClick={(): void => this.onAdd()}
            >
              Add
            </button>
          </div>
        </div>
      </form>
    );
  };
}

interface ManageItemsProps {
  items: (Item & ItemId)[];
  onAdd: (item: Item) => void;
}

export default class ManageItems extends Component<ManageItemsProps, {}> {
  render = (): ReactNode => {
    const { items, onAdd } = this.props;

    return (
      <div className="ui shedkey secondary container segment">
        <h1 className="ui header">Your Items</h1>
        <ItemForm onAdd={onAdd} />
        <ItemsTable items={items} />
      </div>
    );
  };
}
