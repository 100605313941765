import React, { Component, ReactNode, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { ifexv, when } from 'comm/util';
import _ from 'lodash';
import { Status } from 'comm/Common';

interface ValidationError {
  [fieldName: string]: string;
}

interface UIState {
  username: string;
  password: string;
}

interface UIErrors {
  validationError: ValidationError;
}

interface LoginProps {
  onSubmit(state: UIState): void;
  serverError?: number;
}

interface FormSwap {
  formSwap?: boolean;
}

interface Title {
  title: string;
  className?: string;
  button: string;
}

class AuthForm extends Component<LoginProps & Title, UIState & UIErrors> {
  constructor(props: LoginProps & Title) {
    super(props);
    this.state = {
      username: '',
      password: '',
      validationError: {},
    };
  }

  // lifted from http://emailregex.com/, couple unnecessary escapes found and removed
  validationre = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  onSubmit = (): void => {
    const { onSubmit: submit } = this.props;
    const { validationError, ...userInput } = this.state;

    const validators: { [name: string]: [(v: string) => boolean, string] } = {
      username: [
        (s: string): boolean => this.validationre.test(s),
        'Please enter a valid email address',
      ],
      password: [
        (p: string): boolean => p.length >= 6,
        '6 characters or more for your password please.',
      ],
    };

    const errors: { [name: string]: string } = _.chain(userInput)
      .toPairs()
      .map(([k, v]) =>
        when<[string, string]>(
          !(_.has(validators, k) && validators[k][0](v))
        )(() => [k, validators[k][1]])
      )
      .without(undefined)
      .fromPairs()
      .value();

    if (_(errors).isEmpty()) {
      submit(userInput);
    } else {
      this.setState({
        validationError: errors,
      });
    }
  };

  updateField = <K extends keyof UIState>(update: Pick<UIState, K>): void => {
    const validationErrorUpdate = {
      ...this.state.validationError,
      ..._.mapValues(update, () => undefined),
    };

    const stateUpdate: Pick<UIState & UIErrors, K> = {
      validationError: validationErrorUpdate,
      ...update,
    };

    this.setState(stateUpdate);
  };

  render = (): ReactNode => {
    const { username, password, validationError } = this.state;
    const { children, button, className = '', title, serverError } = this.props;

    const messages: { [x: number]: string } = {
      [Status.Conflict]: 'Email is already in use',
      [Status.Unauthorized]: 'Username and password do not match',
    };

    const serverErrorComponent = when<ReactElement>(
      serverError !== undefined
    )(() => (
      <div className="ui basic red label">
        {messages[serverError as number]}
      </div>
    ));

    const errorClasses = _.mapValues(validationError, (v) =>
      ifexv(v === undefined)('')('error')
    );

    return (
      <div className={`ui form ${className} segment`}>
        <h2>{title}</h2>
        <div className={`ui field ${errorClasses['username']}`}>
          <label>Email Address</label>
          <input
            type="text"
            defaultValue={username}
            onBlur={(e): void => this.updateField({ username: e.target.value })}
          />
        </div>
        <div className="ui field">
          <label>Password</label>
          <input
            type="password"
            defaultValue={password}
            onBlur={(e): void => this.updateField({ password: e.target.value })}
          />
        </div>
        <button
          className="ui primary button"
          type="button"
          onClick={(): void => this.onSubmit()}
        >
          {button}
        </button>
        {_.toPairs(validationError).map(([k, v]) =>
          when(v !== undefined)(() => (
            <div key={`error-${k}`} className="ui basic red label">
              {v}
            </div>
          ))
        )}
        {children}
        {serverErrorComponent}
      </div>
    );
  };
}

class FormContainer extends Component<{}, {}> {
  render = (): ReactNode => (
    <div className="ui fluid container shedkey secondary">
      <div className="mobile only large pusher"></div>
      <div className="screen only pusher"></div>
      <div className="ui center aligned middle aligned grid">
        <div className="six wide column" />
        <div className="four wide column">{this.props.children}</div>
        <div className="six wide column" />
      </div>
      <div className="screen only pusher"></div>
    </div>
  );
}

export function Login(props: LoginProps & FormSwap): ReactElement {
  const { formSwap = true, ...rest } = props;
  return (
    <FormContainer>
      <AuthForm title="Welcome Back" button="Log in" {...rest}>
        <div>
          {when<ReactElement>(formSwap)(() => (
            <Link to="/signup">Sign up?</Link>
          ))}
        </div>
      </AuthForm>
    </FormContainer>
  );
}

export function Signup(props: LoginProps & FormSwap): ReactElement {
  const { formSwap = true, ...rest } = props;
  return (
    <FormContainer>
      <AuthForm title="Welcome" button="Sign up" {...rest}>
        <div>
          {when<ReactElement>(formSwap)(() => (
            <Link to="/login">Log in?</Link>
          ))}
        </div>
      </AuthForm>
    </FormContainer>
  );
}
