import React from 'react';
import { Profile, emptyProfile } from 'comm/Profile';
import { ShedMap } from 'comm/Shed';

interface State<T> {
  state: T;
  update: (t: T) => void;
}

interface AppState {
  profile: State<Profile>;
  sheds: ShedMap;
}

export const ReactAppContext = React.createContext<AppState>({
  profile: {
    state: emptyProfile,
    update: (t: Profile): Profile => t,
  },
  sheds: {},
});
