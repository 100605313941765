import React, { Component, ChangeEvent, ReactNode } from 'react';
import { MutFieldProps } from 'comm/Common';
import ItemApi, { BorrowInfo } from 'comm/Items';
import { useParams } from 'react-router-dom';
import { AuthState, AuthResult } from 'comm/Auth';
import { when } from 'comm/util';
import StringPs from 'components/StringPs';

export interface BorrowRespondPageProps {
  onComplete: (message: string, borrowId: string) => void;
  authState: AuthState;
}

interface BorrowRespondPageParams {
  borrowId: string;
  authState: AuthState;
}

interface BorrowRespondPageState {
  borrowInfo?: BorrowInfo;
  response: string;
}

class BorrowRespondPage extends Component<
  BorrowRespondPageProps & BorrowRespondPageParams,
  BorrowRespondPageState
> {
  constructor(props: BorrowRespondPageProps & BorrowRespondPageParams) {
    super(props);

    this.state = {
      response: '',
      borrowInfo: undefined,
    };
  }

  componentDidMount = (): void => {
    ItemApi.respondInfo(this.props.borrowId, this.props.authState).then(
      (info: AuthResult<BorrowInfo>) => {
        this.setState({ borrowInfo: info.data });
      }
    );
  };

  evUpdate: (
    field: string
  ) => (ev: ChangeEvent<HTMLTextAreaElement>) => void = (field) => (
    ev
  ): void => {
    let update = {};
    if (ev === null) {
      return;
    }
    const value: string = ev.target.value;

    if (field === 'response') {
      update = { response: value };
    }

    this.setState(update);
  };

  fieldProps: (field: string) => MutFieldProps<HTMLTextAreaElement> = (
    field
  ) => {
    let value = '';
    if (field === 'response') {
      value = this.state.response;
    }
    return {
      value,
      onChange: this.evUpdate(field),
    };
  };

  render = (): ReactNode => {
    const { onComplete, borrowId } = this.props;
    const { borrowInfo } = this.state;

    const messages = when<ReactNode>(borrowInfo !== undefined)(() => (
      <div className="ui segment">
        {borrowInfo?.messages.map((message, idx) => (
          <StringPs
            message={message.text}
            className="ui message label"
            key={idx}
          />
        ))}
      </div>
    ));

    return (
      <div className="ui shedkey secondary container segment">
        {messages}
        <div className="ui form">
          <div className="field">
            <label>Your response:</label>
            <textarea {...this.fieldProps('response')} />
          </div>
          <button
            className="ui primary button"
            type="button"
            onClick={(): void => onComplete(this.state.response, borrowId)}
          >
            <i className="ui send icon" />
            &nbsp;Send
          </button>
          <div className="ui message label">
            Provide your email address to your neighbor so they can respond to
            you directly.
          </div>
        </div>
      </div>
    );
  };
}

const BorrowRespondPageWrapper: React.FC<BorrowRespondPageProps> = (
  props: BorrowRespondPageProps
) => {
  const { borrowId } = useParams();

  console.log('Show ' + borrowId);

  if (borrowId !== undefined) {
    return <BorrowRespondPage borrowId={borrowId as string} {...props} />;
  }
  return <div>no params</div>;
};

export default BorrowRespondPageWrapper;
