import React, { Component, ReactElement, ReactNode } from 'react';
import { Item, ItemId } from 'comm/Items';

interface ItemsTableProps {
  items: (Item & ItemId)[];
}

export class ItemsTable extends Component<ItemsTableProps, {}> {
  rows = (): ReactElement[] => {
    const { items } = this.props;

    return items.map((item, i) => (
      <tr key={item.itemId || `null-${i}`}>
        <td>{item.name}</td>
        <td>{item.description}</td>
      </tr>
    ));
  };

  render = (): ReactNode => {
    return (
      <table className="ui compact celled definition table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>{this.rows()}</tbody>
      </table>
    );
  };
}
