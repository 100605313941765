import React, { Component, ReactNode } from 'react';
import { ifexv } from 'comm/util';

interface BaseToggleButtonProps {
  onToggle: () => void;
  active: boolean;
}

interface ButtonDef {
  icon: string;
  text: string;
}

interface ToggleButtonProps {
  className: string;
  activeButton: ButtonDef;
  inactiveButton: ButtonDef;
  activeClass?: string;
  inactiveClass?: string;
}

class ToggleButton extends Component<
  BaseToggleButtonProps & ToggleButtonProps,
  {}
> {
  render = (): ReactNode => {
    const {
      onToggle,
      active,
      className,
      activeClass = '',
      inactiveClass = '',
      activeButton,
      inactiveButton,
    } = this.props;

    const extraClass = ifexv<string>(active)(activeClass)(inactiveClass);

    const currentButton = ifexv<ButtonDef>(active)(activeButton)(
      inactiveButton
    );

    return (
      <button
        type="button"
        className={`${className} ${extraClass}`}
        onClick={onToggle}
      >
        <i className={currentButton.icon} />
        {currentButton.text}
      </button>
    );
  };
}

const EditToggleButton: React.FC<BaseToggleButtonProps> = (props) => (
  <ToggleButton
    className="ui primary labeled icon button"
    activeButton={{ icon: 'ui checkmark icon', text: 'Save' }}
    inactiveButton={{ icon: 'ui pencil icon', text: 'Edit' }}
    {...props}
  />
);

export { ToggleButton, EditToggleButton };

export default ToggleButton;
