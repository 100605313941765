export function ifex2<A, B>(
  pred: boolean
): (then: () => A) => (orElse: () => B) => A | B {
  return (then) => (orElse): A | B => {
    if (pred) {
      return then();
    } else {
      return orElse();
    }
  };
}

export function ifex<A>(
  pred: boolean
): (then: () => A) => (orElse: () => A) => A {
  return ifex2(pred);
}

export function ifexv2<A, B>(pred: boolean): (then: A) => (orElse: B) => A | B {
  return function (then: A): (orElse: B) => A | B {
    return function (orElse: B): A | B {
      if (pred) {
        return then;
      }
      return orElse;
    };
  };
}

export function ifexv<A>(pred: boolean): (then: A) => (orElse: A) => A {
  return function (then: A): (orElse: A) => A {
    return function (orElse: A): A {
      return ifexv2<A, A>(pred)(then)(orElse);
    };
  };
}

export function when<A>(pred: boolean): (then: () => A) => A | undefined {
  return (then): A | undefined =>
    ifex2<A, undefined>(pred)(then)(() => undefined);
}

export function whenv<A>(pred: boolean): (then: A) => A | undefined {
  return (then): A | undefined => ifexv2<A, undefined>(pred)(then)(undefined);
}
