import React, { Component, ChangeEvent, ReactNode } from 'react';
import { MutFieldProps, defaultStartingLocation } from 'comm/Common';
import { Shed } from 'comm/Shed';
import { ifex, ifexv } from 'comm/util';
import { ShedkeyMap } from 'components/Map';
import { Redirect } from 'react-router-dom';

export interface CreateShedProps {
  onComplete: (state: Shed) => void;
}

interface UIState {
  editingMap: boolean;
  done: boolean;
}

export default class CreateShed extends Component<
  CreateShedProps,
  Shed & UIState
> {
  constructor(props: CreateShedProps) {
    super(props);

    this.state = {
      name: '',
      description: '',
      location: defaultStartingLocation,
      editingMap: false,
      done: false,
    };
  }

  evUpdate: (field: string) => (ev: ChangeEvent<HTMLInputElement>) => void = (
    field
  ) => (ev): void => {
    let update = {};
    if (ev === null) {
      return;
    }

    const value: string = ev.target.value;

    if (field === 'name') {
      update = { name: value };
    } else if (field === 'description') {
      update = { description: value };
    }

    this.setState(update);
  };

  fieldProps: (field: string) => MutFieldProps<HTMLInputElement> = (field) => {
    let value = '';
    if (field === 'name') {
      value = this.state.name;
    } else if (field === 'description') {
      value = this.state.description;
    }
    return {
      value,
      onChange: this.evUpdate(field),
    };
  };

  onComplete = (): void => {
    this.props.onComplete(this.state);
    this.setState({ done: true });
  };

  render = (): ReactNode => {
    const { location, editingMap, done } = this.state;

    const editingButtonContent = ifexv<string>(editingMap)('Set')('Edit');

    const editingButton = (
      <button
        className="ui primary button"
        onClick={(): void => this.setState({ editingMap: !editingMap })}
      >
        {editingButtonContent}
      </button>
    );

    return ifex<ReactNode>(done)(() => <Redirect to="/account" />)(() => (
      <div className="ui shedkey secondary container segment">
        <h1>Create a shed</h1>
        <div className="ui form">
          <div className="field">
            <label>Name for shed</label>
            <input type="text" {...this.fieldProps('name')} />
          </div>
          <div className="field">
            <label>Enter a short description</label>
            <input
              type="text"
              placeholder="Your local toolshed, search here before the hardware store"
              {...this.fieldProps('description')}
            />
          </div>
          <div className="field">
            <label>Set a center for your shed</label>
            {editingButton}
            <ShedkeyMap
              editable={editingMap}
              location={location}
              updateLocation={(loc): void => this.setState({ location: loc })}
              popup={
                <strong>
                  This will be what users see is the center of your shed.
                  It&apos;s meant to be a guide for users to see if other users
                  are likely to be nearby.
                </strong>
              }
            />
          </div>
          <button className="ui primary button" onClick={this.onComplete}>
            Done
          </button>
        </div>
      </div>
    ));
  };
}
